import { Component, NgModule } from '@angular/core';
import { FeatureWorkstationDetailsModule } from './feature-workstation-details.module';
import { RouterModule } from '@angular/router';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { DETAILS_WORKSTATION_ROUTES } from './feature-workstation-details.routes';
import { RxState } from '@rx-angular/state';

@Component({
    template: '<router-outlet></router-outlet>',
})
export class FeatureWorkstationDetailsComponent {}
@NgModule({
    declarations: [FeatureWorkstationDetailsComponent],
    exports: [FeatureWorkstationDetailsComponent],
    imports: [
        FeatureWorkstationDetailsModule,
        RouterModule.forChild(DETAILS_WORKSTATION_ROUTES),
        MatAutocompleteModule,
    ],
    providers: [RxState],
})
export default class FeatureWorkstepDetailsComponentModule {}
