<div class="hdmui-container">
    <mat-drawer-container>
        <mat-drawer #drawer class="operation__side-nav" mode="side">
            <data-terminal-operation-filters
                (filterChange)="onFilterChange($event)"
                (closeFilterPanel)="drawer.toggle()"
            ></data-terminal-operation-filters>
        </mat-drawer>
        <as-split
            unit="pixel"
            direction="horizontal"
            [gutterStep]="CARD_WIDTH_WITH_MARGINS_PX"
            (dragEnd)="onSplitPanelDragEnd($event)"
        >
            <as-split-area
                class="operation__list"
                [order]="1"
                [size]="splitPanelConfig.leftPanelSizePx"
                [lockSize]="splitPanelConfig.isSplitPanelLocked"
                [minSize]="CARD_WIDTH_WITH_MARGINS_PX + PLACE_FOR_SCROLLBAR_PX"
                [maxSize]="splitPanelConfig.leftPanelMaxSizePx"
            >
                @if ((isLoading$ | async) === true) {
                    <data-terminal-loading-indicator
                        class="operation__center"
                        [label]="'DC.OPERATION_PAGE.LOADING_OPERATIONS' | translate"
                    ></data-terminal-loading-indicator>
                } @else {
                    <data-terminal-operations-list
                        [operations]="(operationList$ | async) ?? []"
                        [otherMachineOperations]="(otherMachineOperationList$ | async) ?? []"
                        [finishedOperations]="(finishedOperationList$ | async) ?? []"
                        [filter]="filter"
                        [operationSettings]="workstationResolvedData.operationSettings"
                        (operationClick)="operationClick($event)"
                        (operationCreation)="onCreateNewOperation($event)"
                        (filterClick)="drawer.toggle()"
                        [selectedOperation]="(selectedOperation$ | async)!"
                        (loadFinishedOperationsClick)="onLoadFinishedOperations($event)"
                        (loadOtherMachineOperationsClick)="onLoadOtherDeviceOperations($event)"
                        (clearAdditionalOperationsClick)="onClearAdditionalOperations()"
                    ></data-terminal-operations-list>
                    <!-- Implement ServiceError as response type -->
                    <!-- <data-terminal-error-message
                    class="operation__center"
                    *isError="opList"
                    [description]="opList.error?.message || 'DC.OPERATION_PAGE.ERROR' | translate"
                ></data-terminal-error-message> -->
                }
            </as-split-area>
            <as-split-area
                class="operation__content"
                [order]="2"
                [lockSize]="splitPanelConfig.isSplitPanelLocked"
                [minSize]="splitPanelConfig.rightPanelMinSizePx"
                [maxSize]="splitPanelConfig.rightPanelMaxSizePx"
            >
                @if ((isLoading$ | async) === true) {
                    <data-terminal-loading-indicator
                        class="operation__center"
                        [label]="'DC.OPERATION_PAGE.LOADING_OPERATION' | translate"
                    ></data-terminal-loading-indicator>
                } @else if (selectedOperation$ | async) {
                    <router-outlet></router-outlet>
                } @else {
                    <hdmui-empty-states icon="hdmui:exclamationMarkCircled">
                        <hdmui-empty-states-title>{{
                            'DC.OPERATIONS.EMPTY.TITLE' | translate
                        }}</hdmui-empty-states-title>
                        <hdmui-empty-states-description
                            >{{ 'DC.OPERATIONS.EMPTY.DESCRIPTION' | translate }}
                        </hdmui-empty-states-description>
                    </hdmui-empty-states>
                }
            </as-split-area>
        </as-split>
    </mat-drawer-container>
</div>
