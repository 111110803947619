@if (activities$ | async; as activities) {
    <section class="other-activities hdmui">
        <div class="other-activities__container hdmui-content">
            @if (activities && activities.length > 0) {
                <div class="other-activities__header">
                    <data-terminal-echart-time-indicator
                        [status]="runningActivity?.actName ?? ''"
                        [currentTime]="runningActivity?.runList?.[0]?.duration ?? 0"
                        [plannedTime]="0"
                        [running]="!!runningActivity"
                    ></data-terminal-echart-time-indicator>
                    <data-terminal-operation-toolbar-buttons
                        (sendReport)="onActionClick($event, runningActivity)"
                        [opState]="runningActivity | activityRunningToState"
                        [stopButtonOnly]="true"
                        [captainFlag]="captainFlag"
                    ></data-terminal-operation-toolbar-buttons>
                </div>
                <data-terminal-activity-buttons
                    [currentState]="runningActivity?.actId || ''"
                    [groupedActivities]="activities"
                    (activityClick)="onActivityClick($event)"
                    [captainFlag]="captainFlag"
                    [actionView]="ActionView.OTHER_ACTIVITIES"
                ></data-terminal-activity-buttons>
            } @else if (activities && activities.length === 0) {
                <hdmui-empty-states icon="hdmui:exclamationMarkCircled">
                    <hdmui-empty-states-title>{{ 'DC.ACTIVITIES.EMPTY.TITLE' | translate }}</hdmui-empty-states-title>
                    <hdmui-empty-states-description>{{
                        'DC.ACTIVITIES.EMPTY.DESCRIPTION' | translate
                    }}</hdmui-empty-states-description>
                </hdmui-empty-states>
            } @else {
                <data-terminal-loading-indicator
                    class="operation-center"
                    [label]="'DC.APP.LOADING' | translate"
                ></data-terminal-loading-indicator>
            }
            <!-- TODO: implementation of DataTerminalError will be discussed in further refinements -->
            <!-- <data-terminal-loading-indicator
                    class="operation-center"
                    *isLoading="activitiesMetadata"
                    [label]="'DC.APP.LOADING' | translate"
                ></data-terminal-loading-indicator>
                <data-terminal-error-message
                    class="operation-center"
                    *isError="activitiesMetadata"
                    [description]="activitiesMetadata.error?.message || 'Something went wrong'"
                ></data-terminal-error-message> -->
        </div>
    </section>
}
