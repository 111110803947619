import { inject, Injectable } from '@angular/core';
import {
    DataTerminalSessionService,
    GLOBAL_RX_STATE,
    GlobalState,
    GlobalStateSelectors,
} from '@data-terminal/data-access';
import { Machine, Role, ROUTE_PARAMS, SignedOnUser } from '@data-terminal/shared-models';
import { RxState } from '@rx-angular/state';
import { map, Observable, withLatestFrom } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

export interface MachineToolbarData {
    machineName: string;
    isCurrentUserAssistant: boolean;
    isCurrentUserCaptain: boolean;
    operator: SignedOnUser | undefined;
}

@Injectable()
export class MachineToolbarService {
    readonly #dataTerminalSessionService = inject(DataTerminalSessionService);
    readonly #globalState: RxState<GlobalState> = inject(GLOBAL_RX_STATE);
    readonly #activatedRoute = inject(ActivatedRoute);

    readonly machineToolbarData$: Observable<MachineToolbarData | undefined> = this.#globalState
        .select(GlobalStateSelectors.USER_MACHINES)
        .pipe(
            withLatestFrom(this.#activatedRoute.paramMap),
            map(([{ data }, params]) => {
                const machineId = params.get(ROUTE_PARAMS.machineId);

                if (!data?.assignedMachines?.length || !machineId) {
                    return undefined;
                }

                const foundMachine = data.assignedMachines.find(
                    (machineFromState) => machineFromState.machineId === machineId.toString()
                );

                if (!foundMachine) {
                    return undefined;
                }

                const currentUser = this.#findLoggedInUserMachineData(foundMachine);

                if (!currentUser) {
                    return undefined;
                }

                return {
                    machineName: foundMachine.machineName,
                    isCurrentUserAssistant: this.#isUserAssistant(currentUser),
                    isCurrentUserCaptain: this.#isUserCaptain(currentUser),
                    operator: this.#findOperator(foundMachine),
                };
            })
        );

    readonly #findLoggedInUserMachineData = (machine: Machine): SignedOnUser | undefined => {
        return machine.signedOnUsers.find(
            (user) => user.userId === this.#dataTerminalSessionService.getCurrentDataTerminalUser().userId
        );
    };

    readonly #isUserAssistant = (user: SignedOnUser): boolean => {
        return user.role === Role.ASSISTANT;
    };

    readonly #isUserCaptain = (user: SignedOnUser): boolean => {
        return user.captain;
    };

    readonly #findOperator = (machine: Machine): SignedOnUser | undefined => {
        return machine.signedOnUsers.find((user) => user.role === Role.OPERATOR);
    };
}
