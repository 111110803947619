import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveData } from '@angular/router';
import { map, Observable } from 'rxjs';
import { Activity, ROUTE_PARAMS } from '@data-terminal/shared-models';
import { getAllParams } from '../get-params-from-path.function';
import { FeatureOperationServiceModule } from '../../../operation-service.module';
import { ActivitiesApiService } from '../../activities-api/activities-api.service';
import { WorkstationResolvedDataKeys } from '../workstation-resolved-data-keys.enum';

@Injectable({
    providedIn: FeatureOperationServiceModule,
})
export class ActivityListResolver {
    constructor(private readonly otherActivitiesService: ActivitiesApiService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Activity[]> {
        const machineId = getAllParams(route)[ROUTE_PARAMS.machineId];
        return this.otherActivitiesService.getActivities(machineId).pipe(map((data) => data || []));
    }
}

export function machineActivityListResolver(): ResolveData {
    return { [WorkstationResolvedDataKeys.ACTIVITIES]: ActivityListResolver };
}
