import { Injectable } from '@angular/core';
import { FeatureOperationServiceModule } from '../../operation-service.module';
import { OperationApiService } from '../operation-api/operation-api.service';
import { Observable, share } from 'rxjs';
import {
    CreateOperation,
    DataTerminalError,
    Operation,
    OperationActionRequest,
    OperationDetails,
    SetCommentParam,
    StartOperationActivity,
} from '@data-terminal/shared-models';
import { Cacheable } from 'ts-cacheable';
import { DataTerminalSessionService } from '@data-terminal/data-access';
import { mapToRequestMetadataWithRetry, RequestMetadata, RETRY_TIME_DELAY_MS } from '@data-terminal/utils';
import { IOTResponseError } from 'projects/shared-models/src/lib/datatransfer';

@Injectable({
    providedIn: FeatureOperationServiceModule,
})
export class OperationService {
    constructor(
        private readonly operationApi: OperationApiService,
        private readonly dataTerminalSessionService: DataTerminalSessionService
    ) {}

    public getOperationRequestMetadata(primaryKey: string): Observable<RequestMetadata<Operation>> {
        return this.operationApi.getOperation(primaryKey).pipe(
            mapToRequestMetadataWithRetry({
                baseDelayMs: RETRY_TIME_DELAY_MS,
            })
        );
    }

    public getOperation(primaryKey: string): Observable<Operation> {
        return this.operationApi.getOperation(primaryKey);
    }

    @Cacheable({ maxAge: 1000 })
    public getOperationDetails(primaryKey: string): Observable<OperationDetails> {
        return this.operationApi.getOperationDetails(primaryKey);
    }

    public getOperationList(machineId: string): Observable<Operation[]> {
        return this.operationApi.getOperationList(machineId).pipe(share());
    }

    public checkOperationListUpdate(machineId: string, timestamp: string): Observable<boolean> {
        return this.operationApi.checkOperationListUpdate(machineId, timestamp);
    }

    public startActivity({
        activity,
        operation,
        machineId,
        currentRunningOperation,
    }: StartOperationActivity): Observable<Operation | DataTerminalError> {
        return this.operationApi.startOperationActivity(activity, operation, machineId, currentRunningOperation);
    }

    public updateOperationAction(operationActionRequest: OperationActionRequest): Observable<Operation> {
        return this.operationApi.updateOperationAction(operationActionRequest);
    }

    public createNewOperation(
        createOperation: CreateOperation
    ): Observable<RequestMetadata<Operation | IOTResponseError | DataTerminalError>> {
        createOperation.userId = this.dataTerminalSessionService.getCurrentDataTerminalUser().userId;
        return this.operationApi.createNewOperation(createOperation).pipe(
            mapToRequestMetadataWithRetry({
                baseDelayMs: RETRY_TIME_DELAY_MS,
            })
        );
    }

    public getOrderBag(primaryKey: string, machineId: string): Observable<string> {
        return this.operationApi.getOrderBag(primaryKey, machineId);
    }

    public printPalletSheet(primaryKey: string, machineId: string): Observable<boolean> {
        return this.operationApi.printPalletSheet(primaryKey, machineId);
    }

    public completedOperationlist(machineId: string, jobId: string): Observable<Operation[]> {
        return this.operationApi.completedOperationlist(machineId, jobId);
    }

    public operationlistByClass(machineId: string, jobId: string): Observable<Operation[]> {
        return this.operationApi.operationlistByClass(machineId, jobId);
    }
    public operationlistByJobId(jobId: string): Observable<Operation[]> {
        return this.operationApi.operationlistByJobId(jobId);
    }

    public getComments(workstep: string): Observable<string[]> {
        return this.operationApi.getComment(workstep);
    }

    public setComment(commentParam: SetCommentParam): Observable<boolean> {
        return this.operationApi.setComment(commentParam);
    }
}
