import { inject, Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    concatMap,
    delay,
    distinctUntilChanged,
    filter,
    map,
    merge,
    mergeMap,
    Observable,
    of,
    ReplaySubject,
    share,
    Subject,
    switchMap,
    tap,
    timer,
    zip,
} from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { RxState } from '@rx-angular/state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    Activity,
    CreateOperation,
    DataTerminalError,
    ErrorCode,
    Machine,
    MachineClass,
    NewOperation,
    Operation,
    OperationAction,
    OperationActionRequest,
    OperationSettingsEntry,
    RequestType,
    ROUTE_PARAMS,
    StartOperationActivity,
} from '@data-terminal/shared-models';
import { ApiRequestType, getParams, isNullOrUndefined, MS_IN_SECOND } from '@data-terminal/utils';
import {
    GLOBAL_RX_STATE,
    GlobalState,
    GlobalStateSelectors,
    OperationNavigationService,
} from '@data-terminal/data-access';

import { IOTResponseError } from 'projects/shared-models/src/lib/datatransfer';

import { OperationService } from '../../services/operation/operation.service';
import { OngoingOperationDialogService } from '../../services/operation/ongoing-operation-dialog.service';
import { UserMachinesService } from '../../../../../data-access/src/lib/user-machines';
import { GeneralActivitiesService } from '../../services/general-activities/general-activities.service';
import { ApiRequestResponseService } from '../../../../../data-access/src/lib/api-request-response/api-request-response.service';
import { ErrorDialogComponent } from '../../../../../../src/app/components/error-dialog/error-dialog/error-dialog.component';
import { GenericUpdatesService } from '../../services/generic-updates/generic-updates.service';
import { NewOperationDialogService } from '../../services/new-operation-dialog/new-operation-dialog.service';
import { ExtraFunctionCallSource } from '../../components/operations/operations-list/operations-list.component';

export interface OrderBagBody {
    machineId: string;
    jobId: string;
    page: number;
}

export interface LoadOtherMachineOperationsBody {
    jobId: string;
    source?: ExtraFunctionCallSource;
}

const TWENTY_SECONDS = 20;
const LOAD_AGAIN_DELAY = 2000;

export interface OperationState {
    operationList: Operation[];
    otherMachineOperationList: Operation[];
    finishedOperationList: Operation[];
    selectedOperationPrimaryKey: string | undefined;
    runningOperationPrimaryKey: string | undefined;
    machineId: string;
    machineClass: MachineClass;
    isLoading: boolean;
}
@UntilDestroy()
@Injectable()
export class OperationPresenter {
    readonly #state: RxState<OperationState> = inject(RxState<OperationState>);

    private readonly operationSettings: OperationSettingsEntry;

    private readonly operationState$ = this.#state.select();

    private readonly OPERATION_LIST_REQUEST_INTERVAL = TWENTY_SECONDS * MS_IN_SECOND;

    private readonly startActivityTrigger$ = new Subject<StartOperationActivity>();
    private readonly performActionTrigger$ = new Subject<OperationActionRequest>();
    private readonly createOperationTrigger$ = new Subject<CreateOperation>();
    private readonly selectOperationTrigger$ = new ReplaySubject<string | undefined>(1);

    private readonly actionPerformed$ = new Subject<void>();
    private readonly activityStarted$ = new Subject<void>();
    private readonly deliveryRequested$ = new Subject<void>();

    private readonly otherMachineOperations$ = new Subject<LoadOtherMachineOperationsBody>();
    private readonly finishedOperations$ = new Subject<string>();

    private allMachinesData: Machine[] = [];
    private operationListInitialLoad = true;
    private checkOperationListTimestamp = new Date().toISOString();
    private counterboxTimestamp = '';

    private readonly handleOngoingOperations = (
        stream$: Observable<StartOperationActivity>
    ): Observable<StartOperationActivity> =>
        stream$.pipe(
            switchMap((startActivity: StartOperationActivity) => {
                const runningOperationPrimaryKey = this.#state.get('runningOperationPrimaryKey');
                if (
                    runningOperationPrimaryKey &&
                    this.allMachinesData.find((machine) => machine.machineId === this.#state.get('machineId'))
                        ?.runningOperation?.primaryKey === runningOperationPrimaryKey
                ) {
                    const runningOperation = this.findOperationByPrimaryKey(
                        this.getListOfAllLoadedOperations(this.#state),
                        runningOperationPrimaryKey
                    );
                    if (runningOperation && runningOperationPrimaryKey !== startActivity.operation.primaryKey) {
                        return this.ongoingOperationDialogService.openOngoingOperationDialog(
                            startActivity,
                            runningOperation,
                            this.#state.get('machineClass') === MachineClass.ID_MANUALPRESS,
                            this.counterboxTimestamp
                        );
                    }
                }
                return of(startActivity);
            })
        );

    private readonly startActivity$ = this.startActivityTrigger$.pipe(
        this.handleOngoingOperations,
        mergeMap((startActivity: StartOperationActivity) =>
            this.operationService.startActivity(startActivity).pipe(
                tap((d) => {
                    if ('error' in d) {
                        this.genericUpdateService.updateOperation(startActivity.operation.primaryKey);
                    } else {
                        this.userMachinesService.triggerUpdateMachines();
                        this.generalActivitiesService.triggerUpdateOtherActivities();
                        this.activityStarted$.next();
                    }
                }),
                filter((d) => !('error' in d)),
                map((d) => d as Operation)
            )
        ),
        share()
    );

    private readonly operationAction$ = this.performActionTrigger$.pipe(
        mergeMap((operationRequestAction: OperationActionRequest) =>
            this.operationService.updateOperationAction(operationRequestAction).pipe(
                tap({
                    complete: () => {
                        this.userMachinesService.triggerUpdateMachines();
                        this.generalActivitiesService.triggerUpdateOtherActivities();
                        this.actionPerformed$.next();
                    },
                })
            )
        ),
        share()
    );

    public readonly createOperation$ = this.createOperationTrigger$.pipe(
        mergeMap((createOperation) => {
            function getOperationCreateRequestType(errorId: ErrorCode): RequestType {
                switch (errorId) {
                    case ErrorCode.ERR_OPERATIONCREATE_MSG_SIZE_EXCEEDED:
                        return RequestType.OPERATIONCREATE_MSG_SIZE_EXEEDED;
                    case ErrorCode.ERR_OPERATIONCREATE_OPERATION_UNDEFINED:
                        return RequestType.OPERATIONCREATE_OPERATION_UNDEFINED;
                    default:
                        return RequestType.OTHER;
                }
            }

            let timeout = true;
            const newOperation = createOperation.newOperation;
            return this.operationService
                .createNewOperation({ ...createOperation, machineId: this.#state.get('machineId') })
                .pipe(
                    tap({
                        next: (data) => {
                            if (data.data !== null && data.data !== undefined) {
                                timeout = false;
                                if ('errorcode' in data.data) {
                                    // Case: IOTResponseError
                                    const errorData = data.data as object as IOTResponseError;
                                    if (errorData.errorcode !== undefined) {
                                        this.dialog
                                            .open(ErrorDialogComponent, {
                                                disableClose: true,
                                                data: {
                                                    errorCode: errorData.errorcode,
                                                    errorMessage: JSON.stringify({
                                                        payload: errorData.payload,
                                                        request: errorData.request,
                                                        deviceId: errorData.deviceId,
                                                    }),
                                                    requestType: errorData.request,
                                                },
                                            })
                                            .afterClosed()
                                            .subscribe({
                                                next: (error: IOTResponseError) => {
                                                    if (error.payload !== undefined) {
                                                        const prevDialogData = (error.payload as CreateOperation)
                                                            .newOperation;
                                                        this.newOperationDialogService
                                                            .openDialogAndFilter(prevDialogData)
                                                            .subscribe({
                                                                next: (report: NewOperation) => {
                                                                    this.triggerCreateOperation(report);
                                                                },
                                                            });
                                                    }
                                                },
                                            });
                                    }
                                }
                                if ('errorId' in data.data) {
                                    // Case: DataTerminalError
                                    const errorData = data.data as object as DataTerminalError;
                                    this.dialog.open(ErrorDialogComponent, {
                                        disableClose: true,
                                        data: {
                                            errorCode: errorData.errorId,
                                            requestType: getOperationCreateRequestType(errorData.errorId as ErrorCode),
                                        },
                                    });
                                }
                            }
                        },
                        complete: () => {
                            if (timeout) {
                                this.dialog.open(ErrorDialogComponent, {
                                    disableClose: true,
                                    data: {
                                        errorCode: ErrorCode.ERR_OPERATIONCREATE_TIMEOUT.toString(),
                                        errorMessage: JSON.stringify({
                                            payload: newOperation,
                                            request: RequestType.OPERATIONCREATE_TIMEOUT,
                                            deviceId: this.#state.get('machineId'),
                                        }),
                                        requestType: RequestType.OPERATIONCREATE_TIMEOUT,
                                    },
                                });
                            }
                        },
                    })
                );
        }),
        filter((d) => !d.isLoading),
        tap((data) => {
            if (!data.isLoading && data.data !== null && data.data !== undefined && 'primaryKey' in data.data) {
                this.triggerSelectOperation(data.data.primaryKey);
                this.router.navigate([data.data.primaryKey], { relativeTo: this.activatedRoute });
            }
        })
    );

    private readonly updateSelectOperation$ = this.genericUpdateService
        .selectedOperationUpdate$()
        .pipe(switchMap((primaryKey) => this.operationService.getOperation(primaryKey)));

    constructor(
        private readonly operationNavigation: OperationNavigationService,
        private readonly operationService: OperationService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly ongoingOperationDialogService: OngoingOperationDialogService,
        private readonly userMachinesService: UserMachinesService,
        private readonly generalActivitiesService: GeneralActivitiesService,
        private readonly apiRequestResponseService: ApiRequestResponseService,
        @Inject(GLOBAL_RX_STATE) private readonly globalState: RxState<GlobalState>,
        private readonly dialog: MatDialog,
        private readonly genericUpdateService: GenericUpdatesService,
        private readonly newOperationDialogService: NewOperationDialogService,
        private readonly router: Router
    ) {
        this.#state.set({
            operationList: [],
            otherMachineOperationList: [],
            finishedOperationList: [],
            selectedOperationPrimaryKey: undefined,
            runningOperationPrimaryKey: undefined,
            isLoading: true,
        });

        this.operationSettings = this.activatedRoute.snapshot.data.operationSettings;

        this.globalState
            .select(GlobalStateSelectors.USER_MACHINES)
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                if (data.data !== null && data.data !== undefined) {
                    this.allMachinesData = data.data.allMachines;
                }
            });

        this.#state.set({
            machineId: getParams(this.activatedRoute)[ROUTE_PARAMS.machineId],
        });

        this.#state.hold(
            this.globalState.select(GlobalStateSelectors.USER_MACHINES).pipe(
                tap((userMachines) => {
                    const currentMachine = userMachines.data?.allMachines.find(
                        (machine) => machine.machineId === this.#state.get('machineId')
                    );
                    this.#state.set({
                        runningOperationPrimaryKey: currentMachine?.runningOperation?.primaryKey,
                        machineClass: currentMachine?.machineClass,
                    });
                })
            )
        );

        this.#state.hold(
            this.selectOperationTrigger$.pipe(
                tap((primaryKey) => {
                    this.#state.set({
                        selectedOperationPrimaryKey: primaryKey,
                    });
                })
            )
        );

        this.#state.hold(
            merge(this.startActivity$, this.operationAction$, this.updateSelectOperation$).pipe(
                tap((operation) => {
                    const operationList = this.#state.get('operationList');
                    if (this.findOperationByPrimaryKey(operationList, operation.primaryKey)) {
                        this.#state.set({
                            operationList: this.sortOperationList([
                                ...operationList.filter((op) => op.primaryKey !== operation.primaryKey),
                                operation,
                            ]),
                        });
                    } else {
                        if (
                            operation.runningOnMachine === this.#state.get('machineId') ||
                            operation.plannedMachine === this.#state.get('machineId')
                        ) {
                            this.#state.set({
                                operationList: this.sortOperationList([...operationList, operation]),
                            });
                        }
                    }
                    const otherMachineOperationList = this.#state.get('otherMachineOperationList');
                    if (
                        this.findOperationByPrimaryKey(otherMachineOperationList, operation.primaryKey) &&
                        operation.runningOnMachine !== this.#state.get('machineId') &&
                        operation.plannedMachine !== this.#state.get('machineId')
                    ) {
                        this.#state.set({
                            otherMachineOperationList: this.sortOperationList([
                                ...otherMachineOperationList.filter((op) => op.primaryKey !== operation.primaryKey),
                                operation,
                            ]),
                        });
                    }
                    const finishedOperationList = this.#state.get('finishedOperationList');
                    if (this.findOperationByPrimaryKey(finishedOperationList, operation.primaryKey)) {
                        this.#state.set({
                            finishedOperationList: this.sortOperationList([
                                ...finishedOperationList.filter((op) => op.primaryKey !== operation.primaryKey),
                                operation,
                            ]),
                        });
                    }
                })
            )
        );

        this.#state.hold(
            this.listenForOperationsList().pipe(
                tap((operationList) => {
                    operationList = this.sortOperationList(operationList);
                    this.#state.set({
                        operationList,
                    });
                    this.#state.set({ isLoading: false });
                })
            )
        );

        this.#state.hold(
            this.otherMachineOperations$.pipe(
                switchMap(({ jobId, source }) =>
                    this.operationService.operationlistByClass(this.#state.get('machineId'), jobId).pipe(
                        tap((otherMachineOperationList) => {
                            if (
                                source === ExtraFunctionCallSource.CODE_SCANNING &&
                                otherMachineOperationList.length === 0 &&
                                this.operationSettings.automaticallyCreateUnplanned
                            ) {
                                this.triggerCreateOperation({ jobId });
                            }
                        })
                    )
                ),
                map((otherMachineOperationList) =>
                    otherMachineOperationList.filter(
                        (operation) =>
                            operation.plannedMachine !== this.#state.get('machineId') &&
                            operation.runningOnMachine !== this.#state.get('machineId')
                    )
                ),
                tap((otherMachineOperationList) => {
                    otherMachineOperationList = this.sortOperationList(otherMachineOperationList);
                    this.#state.set({
                        otherMachineOperationList,
                    });
                })
            )
        );

        this.#state.hold(
            this.finishedOperations$.pipe(
                switchMap((jobId) => this.operationService.completedOperationlist(this.#state.get('machineId'), jobId)),
                tap((finishedOperationList) => {
                    finishedOperationList = this.sortOperationList(finishedOperationList);
                    this.#state.set({
                        finishedOperationList,
                    });
                })
            )
        );
    }

    public triggerStartOperationActivity(
        activity: Activity,
        operation: Operation,
        machineId: string,
        counterBoxTimestamp = ''
    ): void {
        this.counterboxTimestamp = counterBoxTimestamp;
        this.startActivityTrigger$.next({ activity, operation, machineId });
    }

    public triggerSendOperationAction(operationAction: OperationAction, machineId: string, primaryKey: string): void {
        this.performActionTrigger$.next({ operationAction, machineId, primaryKey });
    }

    public triggerCreateOperation(operation: NewOperation): void {
        this.createOperationTrigger$.next({ newOperation: operation });
    }

    public triggerRequestDeliverySent(): void {
        this.deliveryRequested$.next();
    }

    public triggerSelectOperation(primaryKey: string | undefined): void {
        this.selectOperationTrigger$.next(primaryKey);
    }

    public triggerLoadOtherMachineOperations(loadOtherMachineOperationsBody: LoadOtherMachineOperationsBody): void {
        this.otherMachineOperations$.next(loadOtherMachineOperationsBody);
    }

    public triggerLoadFinishedOperations(jobId: string): void {
        this.finishedOperations$.next(jobId);
    }

    public triggerOrderBag(primaryKey: string): Observable<string | object> {
        const body: OrderBagBody = {
            machineId: this.#state.get('machineId'),
            jobId: primaryKey,
            page: 1,
        };
        return this.apiRequestResponseService.sendApiRequest<OrderBagBody, string>(ApiRequestType.ORDERBAG, body).pipe(
            filter((orderBagResponse) => !orderBagResponse.isLoading),
            filter((orderBagResponse) => orderBagResponse.data !== undefined),
            map((orderBagResponse) => orderBagResponse.data || 'ERROR')
        );
    }

    public getOperationActionSent(): Observable<void> {
        return this.actionPerformed$;
    }

    public getIsLoading$(): Observable<boolean> {
        return this.operationState$.pipe(
            map(({ isLoading }) => isLoading),
            distinctUntilChanged()
        );
    }

    public getOperationList$(): Observable<Operation[]> {
        return this.operationState$.pipe(
            map(({ operationList }) => operationList),
            distinctUntilChanged(),
            share()
        );
    }

    public getOtherMachineOperationList$(): Observable<Operation[]> {
        return this.operationState$.pipe(
            map(({ otherMachineOperationList }) => otherMachineOperationList),
            distinctUntilChanged(),
            share()
        );
    }

    public clearOtherMachineOperationList(): void {
        this.#state.set({ otherMachineOperationList: [] });
    }

    public getFinishedOperationList$(): Observable<Operation[]> {
        return this.operationState$.pipe(
            map(({ finishedOperationList }) => finishedOperationList),
            distinctUntilChanged(),
            share()
        );
    }

    public clearFinishedOperationList(): void {
        this.#state.set({ finishedOperationList: [] });
    }

    public getSelectedOperation$(): Observable<Operation | undefined> {
        return this.operationState$.pipe(
            map(({ operationList, otherMachineOperationList, finishedOperationList, selectedOperationPrimaryKey }) => {
                return this.findOperationByPrimaryKey(
                    [...operationList, ...otherMachineOperationList, ...finishedOperationList],
                    selectedOperationPrimaryKey
                );
            }),
            distinctUntilChanged()
        );
    }

    public getSelectedOperation(): Operation | undefined {
        return this.getListOfAllLoadedOperations(this.#state).find(
            (operation) => operation.primaryKey === this.#state.get('selectedOperationPrimaryKey')
        );
    }

    private _checkOperationList(): Observable<Operation[]> {
        return of({}).pipe(
            // of() is necessary since the returned observable is reused but different timestamps are needed
            switchMap(() => {
                const updateCheckOperationListTimestamp = new Date().toISOString();
                return this.operationService
                    .checkOperationListUpdate(this.#state.get('machineId'), this.checkOperationListTimestamp)
                    .pipe(
                        filter((data) => data || this.operationListInitialLoad),
                        switchMap(() =>
                            this.operationService
                                .getOperationList(this.#state.get('machineId'))
                                .pipe(tap(() => (this.operationListInitialLoad = false)))
                        ),
                        tap((d) => {
                            if (d) {
                                this.checkOperationListTimestamp = updateCheckOperationListTimestamp;
                            }
                        })
                    );
            })
        );
    }

    private listenForOperationsList(): Observable<Operation[]> {
        const interval$ = timer(0, this.OPERATION_LIST_REQUEST_INTERVAL);

        const triggerLoadListAgain$ = merge(this.activityStarted$, this.actionPerformed$, this.deliveryRequested$).pipe(
            delay(LOAD_AGAIN_DELAY)
        );

        return merge(triggerLoadListAgain$, interval$, this.createOperation$).pipe(
            untilDestroyed(this),
            concatMap(() => {
                const runningOperationPrimaryKey = this.#state.get('runningOperationPrimaryKey');
                if (runningOperationPrimaryKey && this.isPrimaryKeyCorrect(runningOperationPrimaryKey)) {
                    const runningOperation$ = this.operationService.getOperation(runningOperationPrimaryKey);
                    return zip(this._checkOperationList(), runningOperation$).pipe(
                        map((x) => {
                            return this.createFullOperationList(x);
                        })
                    );
                } else {
                    return this._checkOperationList();
                }
            }),
            share()
        );
    }

    private isPrimaryKeyCorrect(primaryKey: string | undefined): boolean {
        return primaryKey !== undefined && primaryKey !== null && !primaryKey.startsWith('_') && primaryKey.length > 0;
    }

    private createFullOperationList(operations: [Operation[], Operation]): Operation[] {
        const fullOperationList: Operation[] = [];
        const getOperationListData = operations[0];
        if (isNullOrUndefined(getOperationListData)) {
            throw Error('getOperationList Data is undefined');
        }
        const otherMachineOperationData = operations[1];
        if (isNullOrUndefined(otherMachineOperationData)) {
            throw Error('otherMachineOperationData Data is undefined');
        }
        for (const operation of [...getOperationListData.concat(otherMachineOperationData)]) {
            if (!this.findOperationByPrimaryKey(fullOperationList, operation.primaryKey)) {
                fullOperationList.push(operation);
            }
        }

        return this.sortOperationList(fullOperationList);
    }

    private sortOperationList(operationList: Operation[]): Operation[] {
        return operationList.sort((a, b) => {
            const plannedStartA = a.opPlannedStart === 0 ? Infinity : a.opPlannedStart;
            const plannedStartB = b.opPlannedStart === 0 ? Infinity : b.opPlannedStart;
            return plannedStartA - plannedStartB || a.dueDate - b.dueDate;
        });
    }

    private getListOfAllLoadedOperations(state: RxState<OperationState>): Operation[] {
        return [
            ...state.get('operationList'),
            ...state.get('otherMachineOperationList'),
            ...state.get('finishedOperationList'),
        ];
    }

    private findOperationByPrimaryKey(
        operationList: Operation[],
        primaryKey: string | undefined
    ): Operation | undefined {
        return operationList.find((operation) => operation.primaryKey === primaryKey);
    }
}
