import { ChangeDetectorRef, Component, NgModule, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';

import { SettingsDataTransferService } from '@data-terminal/feature-settings';
import { ApiRequestResponseService } from '@data-terminal/data-access';
import { ErrorCode, OperationDetails, RequestType, ROUTE_PARAMS } from '@data-terminal/shared-models';
import { findResolvedData, ResizeService } from '@data-terminal/utils';

import { ErrorDialogComponent } from '../../../../../../src/app/components/error-dialog/error-dialog/error-dialog.component';
import { OperationDetailsInfoModule } from '../../components/operation-details/operation-details-info/operation-details-info.component';
import { OperationDetailsPreviewModule } from '../../components/operation-details/operation-details-preview/operation-details-preview.component';
import { OperationService } from '../../services/operation/operation.service';
import { WorkstationResolvedDataKeys } from '../../services/resolvers/workstation-resolved-data-keys.enum';
import { take } from 'rxjs';

@Component({
    selector: 'data-terminal-operations-details-page',
    templateUrl: './operation-details-page.component.html',
    styleUrls: ['./operation-details-page.component.scss'],
})
export class OperationDetailsPageComponent implements OnInit {
    public operationDetails!: OperationDetails;
    public transfer2Cloud: boolean;

    public readonly isTablet$ = this.resizeService.isTablet$;

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly settingsDataTransferService: SettingsDataTransferService,
        private readonly apiRequestResponseService: ApiRequestResponseService,
        private readonly operationService: OperationService,
        private readonly cdRef: ChangeDetectorRef,
        private readonly dialog: MatDialog,
        private readonly resizeService: ResizeService
    ) {
        this.transfer2Cloud = this.settingsDataTransferService.getDataTransferSettings()?.previews || false;
    }

    public ngOnInit(): void {
        const resolvedOperationDetails = findResolvedData<OperationDetails>(
            this.activatedRoute.snapshot,
            WorkstationResolvedDataKeys.OPERATION_DETAILS
        );

        if (resolvedOperationDetails) {
            this.operationDetails = resolvedOperationDetails;
            this.updateDetails();
        } else {
            throw new Error('Operation details could not be resolved');
        }
    }

    private updateDetails(): void {
        const primaryKey = this.activatedRoute.snapshot.params[ROUTE_PARAMS.primaryKey];
        const machineId = this.activatedRoute.snapshot.params[ROUTE_PARAMS.machineId];
        let timeout = true;
        const detailsInformation = localStorage.getItem(`Details_${machineId}_${primaryKey}`);
        if (detailsInformation !== null && detailsInformation !== undefined) {
            this.operationDetails.operationStatus = JSON.parse(detailsInformation);
        } else {
            this.operationService
                .getOperation(primaryKey)
                .pipe(take(1))
                .subscribe((operation) => {
                    this.operationDetails.operationStatus = operation;
                });
        }
        this.apiRequestResponseService
            .sendPreSettingRequest({
                primaryKey: primaryKey,
                machineId: machineId,
            })
            .subscribe({
                next: (response) => {
                    const responseData = response.data;
                    if (responseData !== undefined && responseData !== null) {
                        timeout = false;
                        this.operationDetails.backPreView = responseData.back;
                        this.operationDetails.frontPreView = responseData.front;
                        this.operationDetails.customerName = responseData.customerName;
                        this.cdRef.detectChanges();
                    }
                },
                error: () => {
                    this.dialog.open(ErrorDialogComponent, {
                        disableClose: true,
                        data: {
                            errorCode: ErrorCode.ERR_GET_PRESETTING,
                            errorMessage: JSON.stringify({
                                payload: {},
                                request: RequestType.PRESETTING,
                                deviceId: this.activatedRoute.snapshot.params[ROUTE_PARAMS.machineId],
                            }),
                            requestType: RequestType.PRESETTING,
                        },
                    });
                },
                complete: () => {
                    if (timeout) {
                        this.dialog.open(ErrorDialogComponent, {
                            disableClose: true,
                            data: {
                                errorCode: ErrorCode.ERR_GET_PRESETTING,
                                errorMessage: JSON.stringify({
                                    payload: {},
                                    request: RequestType.PRESETTING,
                                    devieId: this.activatedRoute.snapshot.params[ROUTE_PARAMS.machineId],
                                }),
                                requestType: RequestType.PRESETTING,
                            },
                        });
                    }
                },
            });
    }
}

@NgModule({
    declarations: [OperationDetailsPageComponent],
    exports: [OperationDetailsPageComponent],
    imports: [OperationDetailsPreviewModule, OperationDetailsInfoModule, CommonModule],
})
export class OperationDetailsPageModule {}
