<hdmui-base-dialog
    [dialogTitle]="('DC.ORDERBAG_DIALOG.HEADER' | translate) + ' - ' + dialogData.orderbagNumber"
    [okText]="'DC.ORDERBAG_DIAOG.DOWNLOAD' | translate"
    (okClicked)="download()"
>
    @for (page of orderBagPages; track page) {
        <div class="report">
            <img src="data:image/jpeg;base64,{{ page }}" alt="Orderbag" />
        </div>
    }
    @if (!loadingComplete) {
        <div class="report__loadingSpinner">
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        </div>
    }
</hdmui-base-dialog>
