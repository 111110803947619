import { Injectable } from '@angular/core';
import { map, merge, mergeMap, Observable, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { Activity, ActivityRequired, ROUTE_PARAMS, User } from '@data-terminal/shared-models';
import { getParams } from '@data-terminal/utils';
import { DataTerminalSessionService, OperationNavigationService } from '@data-terminal/data-access';

import { ActivitiesApiService } from '../activities-api/activities-api.service';

@Injectable()
export class WorkstepActivitiesService {
    public readonly activities$: Observable<Activity[]>;
    private readonly machineId: string;
    private readonly user: User;
    private readonly _activitySubj$ = new Subject<Activity>();

    private triggerType!: 'run' | 'stop';

    private readonly _activity$ = this._activitySubj$.pipe(
        mergeMap((activity) => {
            if (this.triggerType === 'run') {
                return this.runActivity(activity);
            } else {
                return this.stopActivity(activity);
            }
        })
    );

    constructor(
        private readonly activitiesApiService: ActivitiesApiService,
        private readonly operationNavigationService: OperationNavigationService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly dtSessionService: DataTerminalSessionService
    ) {
        this.user = this.dtSessionService.getCurrentDataTerminalUser();
        this.machineId = getParams(this.activatedRoute)[ROUTE_PARAMS.machineId];
        this.activities$ = merge(this.getActivities(), this._activity$).pipe(
            map((data) => this.filterOtherActivities(data))
        );
    }

    public triggerRunActivity(activity: Activity): void {
        this.operationNavigationService.updateOtherActivityTab({
            badge: 1,
            isDisabled: false,
        });
        this.triggerType = 'run';
        this._activitySubj$.next(activity);
    }

    public triggerStopActivity(activity: Activity): void {
        this.operationNavigationService.updateOtherActivityTab({
            badge: 0,
            isDisabled: false,
        });
        this.triggerType = 'stop';
        this._activitySubj$.next(activity);
    }

    private getActivities(): Observable<Activity[]> {
        return this.activitiesApiService.getActivities(this.machineId);
    }

    private runActivity(activity: Activity): Observable<Activity[]> {
        return this.activitiesApiService.runActivity(activity, this.machineId, this.user.userId);
    }

    private stopActivity(activity: Activity): Observable<Activity[]> {
        return this.activitiesApiService.stopActivity(activity, this.machineId, this.user.userId);
    }

    private filterOtherActivities(activities: Activity[]): Activity[] {
        return activities.filter((activity) => activity.required.includes(ActivityRequired.OTHER_ACTIVITY));
    }
}
